import React from 'react';
import { Row, Col } from 'antd';
import AboutTile from '../../AbouTile';
import { stripTags, domHtml } from '../../../utils/stripTags';
import SEO from '../../Seo';




const pageText = {
  header1: `Over 20 years of tutoring experience`,
  section1_para1: `With my extensive years of teaching experience, I am well-versed in the primary school academic syllabus.`,
  section1_para2: `I specialise mainly in PSLE English, Maths and Science. Additionally, I have also taught AEIS to students from China, Indonesia, Thailand, etc. and SPERS to returning Singaporean students from China, Indonesia, etc.`,
  section1_para3: `With a collection of over 20 years of past papers, not only have I compiled materials which effectively reinforce understanding of concepts, I am also able to create drills on the spot in the event where students have exhausted all other sources of practice available.`,
  header2: `Master’s Degree with child psychology background`,
  section2_para1: `I have taught children from a diversified family backgrounds as well as children with special needs. I am able to spot students’ weaknesses and tackle the problem effectively. Over the years, I have developed various learning strategies which have benefited many pupils.`,
  header3: `Dedicated to students`,
  section3_para1: `As a full-time tutor, I am able to provide my students with my utmost attention. I developed customised drills for students who needed special guidance and creative learning aids. As a result, many students under my guidance have shown marked improvement and performed well in PSLE, many of whom have gained admission into top schools. `,
  section3_para2: `In view of the COVID-19 pandemic, I was heartened by the PSLE warriors who are staying calm through this tide, which subsequently inspired me to create teachertrelyn.com. I hope that the videos in this website would be of help to pupils in preparing for their PSLE.`,
  header4: `About teachertrelyn.com`,
  section4_para1: `This website is developed with the following parents’ requests in mind:`,
  header5: `Do you accept a lower rate?`,
  section5_para1: `Worksheets and videos are made available at $2 with uncompromised quality`,
  header6: `Do you have available slot(s) for my child?`,
  section6_para1: `Students can now have access to my lessons through my videos even when my schedule is tight`,
  header7: `Are you available at our preferred time?`,
  section7_para1: `Videos can be watched at the pupils’ convenience`,
  header8: `We do not live in the East, do you travel to the West/North/... ?`,
  section8_para1: `Pupils can now have access to my lessons in the comfort of their own homes without having to travel across the island`,
  header9: `Can you come for extra lessons?`,
  section9_para1: `Videos, which are similar to the lessons, can be watched repeatedly at an affordable price of $2`,
  header10_: `The videos in this website aim to help pupils who are:`,
  header10: `Forgetful`,
  section10_para2: `Mnemonics, stories and special techniques are included in most of the videos to aid learning`,
  header11: `Shy`,
  section11_para1: `Replay videos without having to worry about slowing down a class’ progress for them `,
  header12: `Confused`,
  section12_para1: `Videos follow a consistent framework to present and explain concepts to students, thus avoiding confusion `,
  header13: `Sleepy`,
  section13_para1: `On-demand videos can be watched anytime when pupils are alert and awake`,
  header14: `Perfectionist`,
  section14_para1: `Concepts are organised systematically with colourful and aesthetic visuals`,
  header15: `Visual`,
  section15_para1: `Animations help pupils to visualise concepts and understand them more clearly`,
  header16: `Day dreamer`,
  section16_para1: `Videos can be replayed should the pupils miss any part of the lesson`,
  header17: `Hyper-active`,
  section17_para1: `Topics are broken down into small chunks not lasting more than 12 mins`,
  header18: `How it works:`,
  section18_para1: ``,
  header19: `Video Content`,
  section19_para1: `Watch the video(s) to learn a topic, clarify topics or re-enforce understanding`,
  header20: `Videos are priced at $2 per view`,
  section21_para1: `Chose a topic that you wish to watch and pay for the video. After payment an email will be sent to you, which would contain a link to the video and a one-time password.`,
  header22: `Watch immediately after payment`,
  section22_para1: `After payment, simply click the play icon.`,
  header23: `Watch at a later time after payment`,
  section23_para1: `You may either access the video via the link that has been sent to your email or search for the video on teachertrelyn.com, and then access the video with the password that has been sent to your email with the link.`,
  header24: `The Documents`,
  section24_para1: `Questions with answers related to each video are priced at $2 each which can be downloaded and printed as hard copy.`,
  header25: `teachertrelyn.com reserves the right to amend or remove any documents and content at any given point in the future.`,
  header26: `All content(s) within the videos and documents sold on teachertrelyn.com comprise intellectual property and copyrights of the author and proprietor of Teacher Trelyn; Unauthorised re-distribution in any medium - whole, part, or derivative is strictly prohibited by any individual or entity.`
}

const AboutMe = () => {
  
  return (
    <>
      <div>
        <SEO
          title="Teacher Trelyn: PSLE Tution"
          description="20 years of experience tutoring and helping students excel in PSLE"
          path=""
          keywords={['']}
        />
        <h1 className="titleSeparate">About Teacher Trelyn</h1>

        <h3>{pageText.header1}</h3>
        <p dangerouslySetInnerHTML={domHtml(pageText.section1_para1)} />
        <p dangerouslySetInnerHTML={domHtml(pageText.section1_para2)} />
        <p dangerouslySetInnerHTML={domHtml(pageText.section1_para3)} />

        <h3>{pageText.header2}</h3>
        <p dangerouslySetInnerHTML={domHtml(pageText.section2_para1)} />

        <h3>{pageText.header3}</h3>
        <p dangerouslySetInnerHTML={domHtml(pageText.section3_para1)} />
        <p dangerouslySetInnerHTML={domHtml(pageText.section3_para2)} />

        <br/>
        <br/>

        <h2>{pageText.header4}</h2>
        <p dangerouslySetInnerHTML={domHtml(pageText.section4_para1)} />

        <h3>{pageText.header5}</h3>
        <p dangerouslySetInnerHTML={domHtml(pageText.section5_para1)} />

        <h3>{pageText.header6}</h3>
        <p dangerouslySetInnerHTML={domHtml(pageText.section6_para1)} />

        <h3>{pageText.header7}</h3>
        <p dangerouslySetInnerHTML={domHtml(pageText.section7_para1)} />

        <h3>{pageText.header8}</h3>
        <p dangerouslySetInnerHTML={domHtml(pageText.section8_para1)} />

        <h3>{pageText.header9}</h3>
        <p dangerouslySetInnerHTML={domHtml(pageText.section9_para1)} />

        <br/>
        <br/>

        <h2>{pageText.header10_}</h2>

        <h4>{pageText.header10} 🤦</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section10_para1)} />
        <p dangerouslySetInnerHTML={domHtml(pageText.section10_para2)} />

        <h4>{pageText.header11} &#128534;</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section11_para1)} />

        <h4>{pageText.header12} &#128553;</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section12_para1)} />

        <h4>{pageText.header13} &#128565;</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section13_para1)} />

        <h4>{pageText.header14} 🧐</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section14_para1)} />

        <h4>{pageText.header15} &#129299;</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section15_para1)} />

        <h4>{pageText.header16} &#128566;</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section16_para1)} />

        <h4>{pageText.header17} &#128518;</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section17_para1)} />

        <br/>
        <br/>

        <h2>{pageText.header18}</h2>
        <p dangerouslySetInnerHTML={domHtml(pageText.section18_para1)} />

        <h4>{pageText.header19}</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section19_para1)} />

        <h4>{pageText.header20}</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section20_para1)} />

        <h4>{pageText.header21}</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section21_para1)} />

        <h4>{pageText.header22}</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section22_para1)} />

        <h4>{pageText.header23}</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section23_para1)} />

        <h4>{pageText.header24}</h4>
        <p dangerouslySetInnerHTML={domHtml(pageText.section24_para1)} />

        <h4><i>{pageText.header25}</i></h4>
        <h4><i>{pageText.header26}</i></h4>

      </div>
      
    </>
  );
};
export default AboutMe;
